












































import { FormService } from '@/includes/services/FormService'
import FormAnswerCard from '@/components/Forms/components/FormAnswerCard.vue'
import { FormFieldType, ILastFormAnswer } from '@/includes/types/Form.types'
import { errorNotification } from '@/includes/services/NotificationService'

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    FormAnswerCard,
    CenteredColumnLayout,
    UserMainInfo
  },
  data() {
    return {
      FormFieldType
    }
  }
})
export default class LastFormAnswer extends Vue {
  lastAnswer: ILastFormAnswer | null = null;

  formEmpty = false

  created() {
    const token = this.$route.params.token

    if (token) {
      FormService.getLastFormAnswer('tg', { token })
        .then(res => {
          if (res?.form && res?.answer) {
            this.lastAnswer = res
          } else {
            this.formEmpty = true
          }
        })
        .catch(errorNotification)
    } else {
      errorNotification(this.$t('last_form_answers_token_is_empty').toString())
      this.$router.replace('/')
    }
  }
}
